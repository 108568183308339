import axios from "axios";
import DeviceDetector from "device-detector-js";
import i18n from "i18next";
import Swal from "sweetalert2";

import { FormEvent, useEffect, useState } from "react";

import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { AccessToken } from "../inteface/data";
import { APP_BUILD, APP_VERSION } from "../lib/constants";

const base64 = require("base-64");
const utf8 = require("utf8");

const Login = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const months: Array<string> = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];
  const weekday: Array<string> = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  const [today, setTodayDate] = useState<string>(
    "Wednesday, 29 of September 2021 9:58:25PM"
  );

  const [lang, setLang] = useState<string>(
    localStorage.getItem("lang") || "CN"
  );
  const [loading, setLoading] = useState(false);
  const [remember, setRemember] = useState(false);
  const [pass, setPass] = useState("");
  const [user, setUser] = useState("");

  const onLoginSubmit = async (e: FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(true);

    try {
      let userBytes = utf8.encode(e.currentTarget.account.value.toString());
      let passBytes = utf8.encode(e.currentTarget.password.value.toString());
      let userEncoded: string = base64.encode(userBytes);
      let passEncoded: string = base64.encode(passBytes);

      await axios
        .post(
          "https://rxpu5yyva5.execute-api.ap-east-1.amazonaws.com/" +
            APP_BUILD?.toLowerCase() +
            "/clogin",
          {
            account_id: userEncoded,
            password: passEncoded,
          },
          {
            timeout: 0,
          }
        )
        .then((response) => {
          if (typeof response.data === "object") {
            if (response.data.hasOwnProperty("expires_at")) {
              const auth: AccessToken = {
                credentials: {
                  expires_at: response.data.expires_at,
                  session_token: response.data.session_token,
                  signature: response.data.signature,
                  otp: response.data.otp,
                },
                status: response.data.status,
                user: response.data.user,
              };
              if (remember) {
                const dateTime = new Date();
                dateTime.setTime(dateTime.getTime() + 1 * 24 * 60 * 60 * 1000);

                let expires = "expires=" + dateTime.toUTCString();
                document.cookie = `save=${remember}; ${expires}; path=/`;
                document.cookie = `username=${e.currentTarget.account.value.toString()}; ${expires}; path=/`;
                document.cookie = `password=${e.currentTarget.password.value.toString()}; ${expires}; path=/`;
              } else {
                document.cookie =
                  "save=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
                document.cookie =
                  "username=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
                document.cookie =
                  "password=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
              }
              if (response.data.otp !== true) {
                localStorage.setItem("lang", lang || "CN");
                localStorage.setItem("userInfo", JSON.stringify(auth));
                navigate("/profile");
              } else {
                navigate("/auth", { state: auth });
              }
            } else {
              Swal.fire({
                icon: "error",
                title: `${t("login_failed")}`,
                text: `${t("login_failed_message")}`,
                timer: 3000,
              });
              setLoading(false);
              resetForm();
            }
          } else {
            Swal.fire({
              icon: "error",
              title: `${t("login_failed")}`,
              text: `${t("login_failed_message")}`,
              timer: 3000,
            });
            setLoading(false);
            resetForm();
          }
        })
        .catch((error: any) => {
          Swal.fire({
            icon: "error",
            title: `${t("login_failed")}`,
            text: `${t("login_failed_message")}`,
            timer: 3000,
          });
          setLoading(false);
          resetForm();
        });
    } catch (error: any) {
      Swal.fire({
        icon: "error",
        title: `${t("login_failed")}`,
        text: `${t("login_failed_message")}`,
        timer: 3000,
      });
      setLoading(false);
      resetForm();
    }
  };

  const resetForm = () => {
    setRemember(false);
    setUser("");
    setPass("");
  };

  const getCookie = (cname: string) => {
    let name = cname + "=";
    let ca = document.cookie.split(";");
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === " ") {
        c = c.substring(1);
      }
      if (c.indexOf(name) === 0) {
        return c.substring(name.length, c.length);
      }
    }
    return "";
  };

  const setLanguage = (locale: string) => {
    localStorage.setItem("lang", locale);
    i18n.changeLanguage(locale);
    setLang(locale);
  };

  const startClock = () => {
    const interval = setInterval(() => {
      const objToday = new Date();
      const dayOfWeek = weekday[objToday.getDay()];
      const dayOfMonth = objToday.getDate();
      const curMonth = months[objToday.getMonth()];
      const curYear = objToday.getFullYear();
      let curHour: string | number = objToday.getHours();
      curHour =
        curHour > 12 ? curHour - 12 : curHour < 10 ? "0" + curHour : curHour;
      const curMinute =
        objToday.getMinutes() < 10
          ? "0" + objToday.getMinutes()
          : objToday.getMinutes();
      const curSeconds =
        objToday.getSeconds() < 10
          ? "0" + objToday.getSeconds()
          : objToday.getSeconds();
      const curMeridiem = objToday.getHours() > 12 ? "PM" : "AM";

      const updateToday = `${dayOfWeek}, ${dayOfMonth} of ${curMonth} ${curYear} ${curHour}:${curMinute}:${curSeconds}${curMeridiem}`;
      setTodayDate(updateToday);
    }, 1000);

    return interval;
  };

  useEffect(() => {
    const intervalId = startClock();
    return () => clearInterval(intervalId);
  });

  useEffect(() => {
    const userInfo = localStorage.getItem("userInfo")
      ? JSON.parse(localStorage.getItem("userInfo")!)
      : undefined;

    const getDeviceInfo = async () => {
      const client: any = navigator;
      const deviceDetector = new DeviceDetector();
      const userAgent: string = client.userAgent;
      const netInfo: any =
        client?.connection ||
        client?.mozConnection ||
        client?.webkitConnection ||
        undefined;
      const device: any = deviceDetector.parse(userAgent);

      device.network = {
        type: netInfo?.type || "none",
        ip: "",
      };

      /* await getPublicIp()
        .then((ip) => {
          device.network = {
            type: netInfo ? netInfo.type : "none",
            ip: ip,
          };
          setUserAgent(device);
        })
        .catch((err) => {
          console.info("error ", err);
          device.network = {
            type: netInfo?.type || "none",
            ip: "",
          };
          setUserAgent(device);
        }); */
    };

    if (!userInfo) {
      getDeviceInfo();
    } else {
      navigate("/profile");
    }
  }, [navigate]);

  useEffect(() => {
    let user = getCookie("username");
    let pass = getCookie("password");
    let save = getCookie("save");

    if (user) {
      setUser(user);
    }
    if (pass) {
      setPass(pass);
    }
    if (save) {
      setRemember(save === "true" ? true : false);
    }
  }, []);

  return (
    <>
      <div className="container position-sticky z-index-sticky top-0">
        <div className="row">
          <div className="col-12">
            <nav className="navbar navbar-expand-lg blur border-radius-lg top-0 z-index-3 shadow position-absolute mt-4 py-2 start-0 end-0 mx-4">
              <div className="container-fluid ps-2 pe-0 text-start">
                <img
                  alt="logo"
                  className="navbar-brand-img font-weight-bolder ms-lg-0 ms-3 align-start"
                  src="./assets/img/logo.png"
                  style={{ height: "35px" }}
                />
                <ul className="navbar-nav  d-flex align-items-center">
                  <li className="nav-item px-2">{today}</li>
                </ul>
              </div>
            </nav>
          </div>
        </div>
      </div>
      <main className="main-content  mt-0">
        <section>
          <div className="page-header min-vh-75">
            <div className="container">
              <div className="row">
                <div className="col-xl-4 col-lg-5 col-md-6 d-flex flex-column mx-auto">
                  <div className="card card-plain mt-8">
                    <div className="card-header pb-0 text-left bg-transparent">
                      <h3 className="font-weight-bolder text-warning text-gradient">
                        {t("welcome")}
                      </h3>
                      <p className="mb-0">{t("login_message")}</p>
                    </div>
                    <div className="card-body">
                      <form onSubmit={onLoginSubmit}>
                        <div className="row text-start mb-4">
                          <label className="mb-3">{t("language")}</label>
                          <div className="col-3 text-center">
                            <img
                              alt="korean-flag"
                              src="../assets/img/icons/EN.svg"
                              className={`avatar avatar-sm rounded-circle bg-white ${
                                lang !== "EN"
                                  ? "grayscale-effect"
                                  : " border border-3 border-warning"
                              }`}
                              onClick={() => setLanguage("EN")}
                            />
                          </div>
                          <div className="col-3 text-center">
                            <img
                              alt="korean-flag"
                              src="../assets/img/icons/ZH.svg"
                              className={`avatar avatar-sm rounded-circle bg-white ${
                                lang !== "ZH"
                                  ? "grayscale-effect"
                                  : " border border-3 border-warning"
                              }`}
                              onClick={() => setLanguage("ZH")}
                            />
                          </div>
                          <div className="col-3 text-center">
                            <img
                              alt="korean-flag"
                              src="../assets/img/icons/CN.svg"
                              className={`avatar avatar-sm rounded-circle bg-white ${
                                lang !== "CN"
                                  ? "grayscale-effect"
                                  : " border border-3 border-warning"
                              }`}
                              onClick={() => setLanguage("CN")}
                            />
                          </div>
                          <div className="col-3 text-center">
                            <img
                              alt="korean-flag"
                              src="../assets/img/icons/KR.svg"
                              className={`avatar avatar-sm rounded-circle bg-white ${
                                lang !== "KR"
                                  ? "grayscale-effect"
                                  : " border border-3 border-warning"
                              }`}
                              onClick={() => setLanguage("KR")}
                            />
                          </div>
                        </div>
                        <label>{t("account")}</label>
                        <div className="mb-3">
                          <input
                            id="account"
                            type="text"
                            className="form-control"
                            maxLength={10}
                            placeholder={t("account")}
                            aria-label="Email"
                            aria-describedby="email-addon"
                            required={true}
                            disabled={loading}
                            value={user}
                            onChange={(e) => setUser(e.currentTarget.value)}
                          />
                        </div>
                        <label>{t("password")}</label>
                        <div className="mb-3">
                          <input
                            id="password"
                            type="password"
                            className="form-control"
                            placeholder={t("password")}
                            aria-label="Password"
                            aria-describedby="password-addon"
                            required={true}
                            disabled={loading}
                            value={pass}
                            onChange={(e) => setPass(e.currentTarget.value)}
                          />
                        </div>
                        <div className="text-center">
                          <button
                            type="submit"
                            disabled={loading}
                            className="btn bg-gradient-warning w-100 mt-4 mb-0"
                          >
                            {loading && (
                              <span>
                                <span
                                  className="spinner-grow spinner-grow-sm"
                                  role="status"
                                  aria-hidden="true"
                                ></span>
                                &nbsp;&nbsp;&nbsp;
                              </span>
                            )}
                            {t("signin")}
                          </button>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="oblique position-absolute top-0 h-100 d-md-block d-none me-n8">
                    <div
                      className="oblique-image bg-cover position-absolute fixed-top ms-auto h-100 z-index-0 ms-n6"
                      style={{
                        backgroundImage: `url(${"./assets/img/curved-images/curved5.jpg"})`,
                        backgroundSize: "cover",
                      }}
                    ></div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>

      <footer className="footer py-5">
        <div className="container">
          <div className="row">
            <div className="col-12 mx-auto text-center mt-1 text-xxs">
              {`Version ${APP_VERSION} ${APP_BUILD?.toLowerCase()} `}
            </div>
            <div className="col-8 mx-auto text-center mt-1">
              <p className="mb-0 text-xs text-secondary">
                Copyright ©{" " + new Date().getFullYear() + " "}
                OGTING.com. All rights reserved.
              </p>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};

export default Login;
